//
// Bootstrap & Custom Variables
// Safely override any variable in _variables.custom.scss
//

$gray-100-dark: #1b1b29 !default;
$gray-200-dark: #2b2b40 !default;
$gray-300-dark: #323248 !default;
$gray-400-dark: #474761 !default;
$gray-500-dark: #565674 !default;
$gray-600-dark: #6d6d80 !default;
$gray-700-dark: #92929f !default;
$gray-800-dark: #cdcdde !default;
$gray-900-dark: #ffffff !default;

$grays-dark: (
  '100': $gray-100-dark,
  '200': $gray-200-dark,
  '300': $gray-300-dark,
  '400': $gray-400-dark,
  '500': $gray-500-dark,
  '600': $gray-600-dark,
  '700': $gray-700-dark,
  '800': $gray-800-dark,
  '900': $gray-900-dark,
) !default;

// Bootstrap muted color
$text-muted-dark: $gray-500-dark !default;

// Bootstrap contextual colors
// Primary colors
$primary-light-dark: #212e48 !default;

// Secondary colors
$secondary-dark: $gray-300-dark !default;
$secondary-active-dark: $gray-400-dark !default;
$secondary-light-dark: $gray-100-dark !default;
$secondary-inverse-dark: $gray-700-dark !default;

// Light colors
$light-dark: $gray-200-dark !default;
$light-active-dark: $gray-300-dark !default;
$light-inverse-dark: $gray-600-dark !default;

// Success colors
$success-light-dark: #1c3833 !default;

// Info colors
$info-light-dark: #2f264f !default;

// Warning colors
$warning-light-dark: #392f28 !default;

// Danger colors
$danger-light-dark: #3a2434 !default;

// Dark colors
$dark-dark: $gray-900-dark !default;
$dark-active-dark: lighten($gray-900-dark, 3%) !default;
$dark-light-dark: $gray-200-dark !default;
$dark-inverse-dark: $gray-100-dark !default;

$theme-colors-dark: (
  'white': $white,
  // custom color type
  'light': $light-dark,
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'dark': $dark-dark,
  'secondary': $secondary-dark,
) !default;

$theme-active-colors-dark: (
  'primary': $primary-active,
  'secondary': $secondary-active-dark,
  'light': $light-active-dark,
  'success': $success-active,
  'info': $info-active,
  'warning': $warning-active,
  'danger': $danger-active,
  'dark': $dark-active-dark,
) !default;

$theme-inverse-colors-dark: (
  'primary': $primary-inverse,
  'secondary': $secondary-inverse-dark,
  'light': $light-inverse,
  'success': $success-inverse,
  'info': $info-inverse,
  'warning': $warning-inverse,
  'danger': $danger-inverse,
  'dark': $dark-inverse-dark,
) !default;

$theme-light-colors-dark: (
  'primary': $primary-light-dark,
  'success': $success-light-dark,
  'info': $info-light-dark,
  'warning': $warning-light-dark,
  'danger': $danger-light-dark,
  'dark': $dark-light-dark,
  'secondary': $secondary-light-dark,
) !default;

$theme-text-colors-dark: (
  'white': $white,
  'primary': $primary,
  'secondary': $secondary-dark,
  'light': $light-dark,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'dark': $dark-dark,
  'muted': $text-muted-dark,
  'gray-100': $gray-100-dark,
  'gray-200': $gray-200-dark,
  'gray-300': $gray-300-dark,
  'gray-400': $gray-400-dark,
  'gray-500': $gray-500-dark,
  'gray-600': $gray-600-dark,
  'gray-700': $gray-700-dark,
  'gray-800': $gray-800-dark,
  'gray-900': $gray-900-dark,
) !default;

// Body
//
// Settings for the `<body>` element.
$body-bg-dark: #1e1e2d !default;
$body-bg-rgb-dark: to-rgb($body-bg-dark) !default;
$body-color-dark: $gray-900-dark !default;

// Links
//
// Style anchor elements.
$link-color-dark: $primary !default;

// Components
//
// Define common padding and border radius sizes and more.
$border-color-dark: $gray-200-dark !default;
$border-dashed-color-dark: $gray-300-dark !default;

// KT hover states
$component-hover-color-dark: $primary !default;
$component-hover-bg-dark: $gray-100-dark !default;

// KT active states
$component-active-color-dark: $primary-inverse !default;
$component-active-bg-dark: $primary !default;

// KT checked states
$component-checked-color-dark: $primary-inverse !default;
$component-checked-bg-dark: $primary !default;

$headings-color-dark: $gray-900-dark !default;
$blockquote-footer-color-dark: $gray-600-dark !default;

// Box shadow
$box-shadow-xs-dark: 0 0.1rem 0.75rem 0.25rem rgba($black, 0.05) !default;
$box-shadow-sm-dark: 0 0.1rem 1rem 0.25rem rgba($black, 0.05) !default;
$box-shadow-dark: 0 0.5rem 1.5rem 0.5rem rgba($black, 0.075) !default;
$box-shadow-lg-dark: 0 1rem 2rem 1rem rgba($black, 0.1) !default;
$box-shadow-inset-dark: inset 0 1px 2px rgba($black, 0.075) !default;

// Card
$card-box-shadow-dark: null !default;

// Tables
$table-striped-bg-dark: rgba($gray-100-dark, 0.75) !default;
$table-loading-message-box-shadow-dark: 0px 0px 30px rgba(0, 0, 0, 0.3) !default;

// Forms
$form-select-indicator-color-dark: $gray-600-dark !default;
$form-select-indicator-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;
$form-switch-color-dark: rgba($white, 0.25) !default;
$form-switch-color-solid-dark: $gray-500-dark !default;
$form-switch-bg-image-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-dark}'/></svg>") !default;
$form-switch-bg-image-solid-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-solid-dark}'/></svg>") !default;

// Accordion
$accordion-icon-color-dark: $body-color-dark !default;
$accordion-icon-active-color-dark: $primary !default;
$accordion-button-icon-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

// Dropdowns
$dropdown-bg-dark: $body-bg-dark !default;
$dropdown-box-shadow-dark: 0px 0px 30px rgba(0, 0, 0, 0.3) !default;

// Popover
$popover-bg-dark: $gray-200-dark !default;
$popover-border-color-dark: $gray-200-dark !default;
$popover-box-shadow-dark: $dropdown-box-shadow-dark !default;
$popover-header-border-color-dark: $gray-300-dark !default;

// Toasts
$toast-background-color-dark: $gray-200-dark !default;
$toast-header-background-color-dark: $gray-200-dark !default;
$toast-header-border-color-dark: $gray-300-dark !default;

// Tooltip
$tooltip-bg-dark: $gray-200-dark !default;
$tooltip-box-shadow-dark: 0px 0px 30px rgba(0, 0, 0, 0.15) !default;

// Code
$code-bg-dark: $gray-200-dark !default;
$code-color-dark: #b93993 !default;
$code-box-shadow-dark: 0px 3px 9px rgba(0, 0, 0, 0.08) !default;

// Symbol
$symbol-border-color-dark: rgba($body-bg, 0.5);
$symbol-label-color-dark: $gray-800-dark;
$symbol-label-bg-dark: $gray-100-dark;

// KT bullet component
$bullet-bg-color-dark: $gray-400-dark !default;

// KT scrolltop component
$scrolltop-opacity-dark: 0 !default;
$scrolltop-opacity-on-dark: 0.3 !default;
$scrolltop-opacity-hover-dark: 1 !default;
$scrolltop-box-shadow-dark: $box-shadow !default;
$scrolltop-bg-color-dark: $primary !default;
$scrolltop-bg-color-hover-dark: $primary !default;
$scrolltop-icon-color-dark: $primary-inverse !default;
$scrolltop-icon-color-hover-dark: $primary-inverse !default;

// KT drawer component
$drawer-box-shadow-dark: 0px 0px 30px rgba(0, 0, 0, 0.1) !default;
$drawer-bg-color-dark: $body-bg-dark !default;
$drawer-overlay-bg-color-dark: rgba($black, 0.4) !default;

// KT menu component
$menu-dropdown-box-shadow-dark: $dropdown-box-shadow-dark !default;
$menu-dropdown-bg-color-dark: $dropdown-bg-dark !default;
$menu-link-color-hover-dark: $component-hover-color-dark !default;
$menu-link-color-show-dark: $component-hover-color-dark !default;
$menu-link-color-here-dark: $component-hover-color-dark !default;
$menu-link-color-active-dark: $component-hover-color-dark !default;
$menu-link-bg-color-hover-dark: $component-hover-bg-dark !default;
$menu-link-bg-color-show-dark: $component-hover-bg-dark !default;
$menu-link-bg-color-here-dark: $component-hover-bg-dark !default;
$menu-link-bg-color-active-dark: $component-hover-bg-dark !default;
$menu-heading-color-dark: $text-muted-dark !default;

// KT scrollbar component
$scrollbar-color-dark: $gray-200-dark !default;
$scrollbar-hover-color-dark: darken($gray-200-dark, 2%) !default;

// KT overlay component
$overlay-bg-dark: rgba($white, 0.05) !default;

// KT blockui component
$blockui-overlay-bg-dark: rgba($white, 0.05) !default;
$blockui-message-bg-dark: $tooltip-bg-dark !default;
$blockui-message-box-shadow-dark: $tooltip-box-shadow-dark !default;

// KT rating component
$rating-color-default-dark: $gray-400-dark !default;
$rating-color-active-dark: #ffad0f !default;

// KT ribbon component
$ribbon-label-box-shadow-dark: 0px -1px 5px 0px rgba($white, 0.1) !default;
$ribbon-label-bg-dark: $primary !default;
$ribbon-label-border-color-dark: darken($primary, 20%) !default;
$ribbon-clip-bg-dark: $light !default;

// Engage panel
$engage-btn-bg-dark: $gray-200-dark;
$engage-btn-box-shadow-dark: 0px 0px 30px rgba(0, 0, 0, 0.3);
$engage-btn-border-color-dark: $border-color-dark;
$engage-btn-color-dark: $gray-800-dark;
$engage-btn-icon-color-dark: $gray-600-dark;
$engage-btn-color-active-dark: $gray-800-dark;
