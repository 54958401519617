.video-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.video-item {
  flex: 1 1 calc(23.333% - 20px);
  max-width: calc(26.333% - 20px);
  box-sizing: border-box;
}

/* Gallary Styling */

.image-container {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  opacity: 0;
  transition: opacity 0.3s ease; /* Smooth transition */
  color: white;
}

.image-container:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.image-title {
  font-size: 1.2rem;
  text-align: center;
}

.image-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
}

.image-container:hover .overlay {
  opacity: 1;
}

.image-title {
  font-size: 1rem;
  text-align: center;
}

.indicator-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.dot.active {
  opacity: 1;
  background-color: white;
}

.image-con-width {
  width: 190px;
}

.image-con-height {
  height: 160px;
}

@media only screen and (min-width: 768px) {
  .image-con-width {
    width: 170px;
  }
}

@media only screen and (min-width: 600px) {
  .image-con-width {
    width: 131px;
  }

  .image-con-height {
    height: 110px;
  }
}

@media only screen and (max-width: 600px) {
  .image-con-width {
    width: 90px;
  }

  .image-con-height {
    height: 80px;
  }
}

@media only screen and (min-width: 992px) {
  .image-con-width {
    width: 160px;
  }

  .image-con-height {
    height: 120px;
  }
}
