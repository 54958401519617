@media (min-width: 1200px) {
  .custom-modal {
    min-width: 900px;
  }
}

.custom-modal-content {
  /* overflow-y: auto; */
  max-height: 90vh;
}

@media (min-width: 1200px) {
  .custom-modal-img-select {
    min-width: 700px;
  }
}

@media (min-width: 1200px) {
  .custom-modal-audio-select {
    min-width: 850px;
  }
}

.custom-modal-content-img-select {
  /* overflow-y: auto; */
  max-height: 80vh;
}

.video-responsive {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
