.otp-input {
  height: 45px;
  width: 42px;
  margin-right: 10px;
  border-radius: 6px;
  outline: none;
  text-align: center;
  border: 1px solid #ddd;
}

.verify-icon {
  font-size: 84px !important;
  /* display: flex;
  justify-content: center; */
}

.icon {
  position: absolute;
  right: 10px; /* Adjust the right position as needed */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
}

.reg-con {
  height: 65vh;
}

.con-max-width {
  max-width: 350px !important;
}

/* index.css */

.login-light-mode {
  /* Define light mode styles here */
  /* background-color: #ffffff; */
  color: #000000;
  /* Add other light mode styles as needed */

  /* Ensure all elements within this class adhere to light mode styles */
  .form-control {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #ced4da;
  }

  .form-label {
    color: #495057;
  }

  .login-btn {
    background-color: #007bff;
    color: #ffffff;
  }

  .login-text {
    color: #007bff;
  }

  .alert {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
  }

  /* Add other necessary styles to override dark mode */
}

.btn-no-hover {
  background-color: #f9f9f9 !important;
}

.btn-no-hover:hover {
  background-color: #f9f9f9 !important;
  color: inherit; /* Maintain the text color on hover */
  border-color: inherit; /* Maintain the border color on hover */
  /* Add any other styles you want to retain on hover */
}
